export const replaceString = (
  translations: Record<string, string>,
  id: string,
  placeholder: Record<string, string>,
): string => {
  let trans = translations[id];
  if (Object.keys(placeholder).length > 0) {
    Object.entries(placeholder).forEach(([replacement, translation]) => {
      trans = trans?.replace(`%%${replacement}%%`, translation);
      trans = trans?.replace(`%${replacement}%`, translation);
    });
  }

  return trans ?? '';
};
