import { useCallback } from 'react';
import { useSelector } from 'react-redux';

// utils
import {
  translationByKeySelector,
  translationKeysSelector,
} from 'utils/selectors/translationSelectors';
import { useIsPreview } from './useIsPreview';
import { decodingContent } from '../decodingContent';
import { replaceString } from '../translation';

export function useTranslations() {
  return useSelector(translationKeysSelector);
}

export function useTranslationByKey(key: string) {
  return useSelector((state: AppState) => translationByKeySelector(state, key));
}

export function useTranslationFunction() {
  const translationsKeys = useSelector(translationKeysSelector);
  const isPreview = useIsPreview();

  return useCallback(
    (key: string, fallbackOnPreview = true, placeholder?: Record<string, string>) => {
      const fallback = (fallbackOnPreview && isPreview && key) || '';
      if (!translationsKeys) return fallback;

      if (placeholder) {
        return decodingContent(replaceString(translationsKeys, key, placeholder)) ?? fallback;
      }

      const translation = translationsKeys[key];
      return translation ?? fallback;
    },
    [translationsKeys, isPreview],
  );
}
