import { Link, Button, type LinkProps } from '@geberit/gdds';
import React, { type PropsWithChildren, type ReactElement } from 'react';
import { useRouter } from 'next/navigation';
import key from 'weak-key';

// types
import type { ContentProps } from './ContentProps';

// components
import { PopUpLink } from './PopUpLink';
import { CookieLink } from './CookieLink';

// utils
import { decodingContent } from 'utils/decodingContent';
import { addArrowClass } from 'utils/linkArrowClass';
import { useTracking } from 'utils/hooks/useTracking';
import { internalLink, internalMediumLink, internalFooterLink, popUpLink } from './trackingActions';
import { useGdds } from 'utils/hooks/use-gdds';
import { classNameBuilder as buildClassName } from 'utils/classNameBuilder';
import { handlePopup } from 'utils/openInPopup';
import { useNord } from 'utils/hooks/use-nord';
import { isEmpty } from 'utils/is-empty';
import { useTracking as useTracking2 } from 'utils/tracking/track';

export type InternalLinkProps = {
  content: ContentProps;
  className?: string;
  onClick?(e: React.MouseEvent<HTMLAnchorElement>): void;
  tracking?(e: React.MouseEvent<HTMLAnchorElement>): void;
  onMouseDown?(e: React.MouseEvent<HTMLAnchorElement>): void;
  onMouseUp?(e: React.MouseEvent<HTMLAnchorElement>): void;
  category?: string;
  footerAction?: string;
  footerLabel?: string;
  linkType?: string;
  elementType?: string;
  standardFontSize?: boolean;
  stylingType?: LinkProps['stylingType'];
  alignByContent?: LinkProps['alignByContent'];
  inContext?: boolean;
  icon?: boolean | string;
  isNavigationItem?: boolean;
};

export function InternalLink(props: PropsWithChildren<InternalLinkProps>) {
  const {
    content,
    content: {
      window: linkWindow,
      target,
      localMedium,
      showArrow = false,
      text,
      type,
      buttonStyle,
      download,
    },
    className = '',
    children,
    onClick,
    tracking,
    onMouseUp,
    onMouseDown,
    footerAction,
    footerLabel,
    linkType,
    elementType,
    stylingType,
    standardFontSize = true,
    alignByContent,
    inContext,
    icon,
    isNavigationItem,
  } = props;
  const track = useTracking();
  const { trackClick } = useTracking2();
  const router = useRouter();
  const isGdds = useGdds();
  const isNord = useNord();
  const linkText = isEmpty(children) ? decodingContent(text) : children;
  const isButton = type === 'internal_button';
  const windowTarget = linkWindow;
  const classes = `${className} ${addArrowClass(className, showArrow)}`;
  const isCTA = elementType === 'call_to_action_button';

  if (!isButton && linkWindow === '_popup') {
    return <PopUpLink {...props} />;
  }
  if (linkType === 'cookie_disclaimer') {
    return <CookieLink {...props}></CookieLink>;
  }

  const attributes: React.AllHTMLAttributes<HTMLAnchorElement | HTMLButtonElement> & {
    to: string;
  } = {
    to: target,
    className: classes,
    target: windowTarget,
    rel: 'noopener noreferrer',
    onMouseUp,
    onMouseDown,
  };

  if (isEmpty(classes)) {
    delete attributes.className;
  }
  if (target !== '_blank') {
    delete attributes.rel;
  }

  const onLinkClicked = (e) => {
    const link = e.currentTarget.href;

    if (attributes.target !== '_blank' && (isGdds || (isNord && isNavigationItem)) && !download) {
      e.preventDefault();
    }

    if (link?.toLowerCase()?.endsWith('.pdf')) {
      trackClick({
        click_intent: 'download_pdf',
        click_url: link,
        file_name: link?.split('/')?.pop() ?? '',
      });
    }

    if (download) {
      // do not push downloads to the router history to prevent back button issues
      // instead we rely on the standard browser behavior
      return;
    }
    if (isButton && windowTarget === '_popup') {
      const windowId = key(content);
      handlePopup(content, windowId);
    }
    if (
      (windowTarget !== '_popup' && isGdds && attributes.target !== '_blank' && attributes.to) ||
      (windowTarget !== '_popup' && isButton && isGdds)
    ) {
      router.push(attributes.to);
    }
    if (!isGdds && windowTarget !== '_blank' && !download) {
      e.preventDefault();
      router.push(attributes.to);
    }
    if (onClick) {
      onClick(e);
    }
    // Link has owntracking or fire general internal tracking
    // if it is a local medium it should trigger a specific downloads event
    let click_url = '';
    if (tracking) {
      tracking(e);
      if (localMedium) {
        track.trackEvent(internalMediumLink(link));
      }
    } else if (windowTarget === '_popup') {
      click_url = content.target;
      track.trackEvent(popUpLink(content.target));
    } else if (footerAction) {
      track.trackEvent(internalFooterLink(footerAction, footerLabel));
    } else {
      click_url = link;
      track.trackEvent(internalLink(link));
    }
    if (!tracking) {
      trackClick({
        click_intent: 'internal_link',
        click_element: 'hyperlink',
        click_text: typeof linkText === 'string' ? linkText : undefined,
        click_url,
      });
    }
  };

  /*
   * If isGdds GDDS-Link usage
   */
  if (isGdds && !isCTA) {
    let defaultStylingType: 'secondary' | 'primary' = 'secondary';
    if (!showArrow) {
      defaultStylingType = 'primary';
    }

    let alignment = alignByContent;
    if (!alignment) {
      alignment = showArrow || inContext ? undefined : 'left';
    }

    const LinkComponent = isButton ? Button : Link;
    const linkStylingType = isButton ? buttonStyle : (stylingType ?? defaultStylingType);
    const linkTextContent = isButton ? linkText : '';

    return (
      <LinkComponent
        stylingType={linkStylingType}
        text={linkText as string}
        href={attributes.to}
        windowTarget={attributes.target}
        hasArrow={showArrow}
        onClick={onLinkClicked}
        standardFontSize={standardFontSize}
        alignByContent={!isButton ? alignment : undefined}
        inContext={inContext}
        icon={icon as string}
        className={buildClassName(className, isNord && 'isNordicsStyle')}
        inheritFontSize={!isButton}
      >
        {linkTextContent as ReactElement}
      </LinkComponent>
    );
  }

  /*
   * renders an internal link which should open in the same window
   */
  const { to: href, ...rest } = attributes;
  if (download && rest.target !== '_blank') {
    // next/link ignores the download attribute. so we use the standard link to download files (not in a new window)
    return (
      <a
        {...rest}
        href={href}
        download
        onClick={onLinkClicked}
        aria-label={text || target || undefined}
      >
        {linkText}
      </a>
    );
  }

  /*
   * If !isGdds <a> usage
   */
  return (
    <a {...rest} href={href || ''} onClick={onLinkClicked} aria-label={text || target || undefined}>
      {linkText}
    </a>
  );
}
