import type { HTMLProps } from 'react';
import { useSelector } from 'react-redux';

// components
import { TranslationEditWrapper } from 'components/ContentCreator/TranslationEditWrapper';

// utils
import { translationKeysSelector } from 'utils/selectors/translationSelectors';
import { decodingContent } from 'utils/decodingContent';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { replaceString } from 'utils/translation';

interface ITranslationProps extends Omit<HTMLProps<HTMLSpanElement>, 'placeholder'> {
  id: string;
  placeholder?: Record<string, string>;
  translations?: Record<string, string>;
  isPreview?: boolean;
  isStaticPage?: boolean;
}

export function Translation(
  props: ITranslationProps & Omit<HTMLProps<HTMLSpanElement>, 'placeholder'>,
) {
  const { id, placeholder = {}, isStaticPage = false, ...spanProps } = props;
  const translations = useSelector(translationKeysSelector) || {};
  const isPreview = useIsPreview();

  if (!translations[id]) {
    return isPreview && !isStaticPage ? (
      <TranslationEditWrapper {...spanProps} translationId={id} hasError>
        {id}
      </TranslationEditWrapper>
    ) : null;
  }

  const content = decodingContent(replaceString(translations, id, placeholder));

  if (isPreview && !isStaticPage) {
    return (
      <TranslationEditWrapper {...spanProps} translationId={id}>
        {content}
      </TranslationEditWrapper>
    );
  }

  return <>{content}</>;
}
