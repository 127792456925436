import { useRef, type JSX } from 'react';

// types
import type { ImageDataType, ImageProps } from './image.types';

// components
import { ImageEditWrapper } from 'components/ContentCreator/ImageEditWrapper';
import { AspectRatio } from './aspect-ratio';

// utils
import { decodingContent } from 'utils/decodingContent';
import { useLazyLoad } from 'utils/hooks/useLazyLoad';

function getImagePath(src: string | undefined, data?: ImageDataType) {
  if (!data) {
    return src;
  }

  if (typeof data === 'string') {
    return data;
  }

  return data.url;
}

export function Image(
  props: Readonly<React.PropsWithChildren<ImageProps & React.HTMLAttributes<HTMLDivElement>>>,
) {
  const {
    alt,
    aspectRatio,
    children,
    data,
    external = false,
    format,
    isInLightbox = false,
    isSectionReference = false,
    lazy = false,
    title,
    src,
    shouldHaveImgWrapper = false,
    staticImage = false,
    useFormatAsAspectRatio = true,
    ...rest
  } = props;
  const imgRef = useRef<HTMLImageElement>(null);
  const imagePath = getImagePath(src, data);
  const lazyImagePath = useLazyLoad(imgRef, imagePath);
  const finalImagePath = lazy ? lazyImagePath : imagePath;

  const renderWrapper = (wrapperChildren: JSX.Element) => {
    if (shouldHaveImgWrapper) {
      return <div className="image-content-wrapper">{wrapperChildren}</div>;
    }

    return wrapperChildren;
  };

  const imgSafeProps = {
    src,
    ...rest,
  };

  // no aspect ratio needed for static images
  if (staticImage) {
    return (
      <img
        {...imgSafeProps}
        src={external ? src || (data as ImageObject)?.url : src}
        alt={decodingContent(alt)}
        title={decodingContent(title)}
      />
    );
  }

  // if there is no data passed it shouldn't be editable and it has a src path
  if (!data && format) {
    const formatAsAspectRatio = useFormatAsAspectRatio && format ? format : undefined;
    return (
      <AspectRatio aspectRatio={aspectRatio || formatAsAspectRatio}>
        {src && (
          <img
            {...imgSafeProps}
            {...(finalImagePath ? { src: finalImagePath } : {})}
            alt={decodingContent(alt)}
            title={decodingContent(title)}
            ref={imgRef}
          />
        )}
      </AspectRatio>
    );
  }

  if (!data) {
    return (
      <img
        {...imgSafeProps}
        {...(finalImagePath ? { src: finalImagePath } : {})}
        alt={decodingContent(alt)}
        title={decodingContent(title)}
        ref={imgRef}
      />
    );
  }

  const isSVG = (data as ImageObject).url && /\.svg$/i.test((data as ImageObject).url);
  // when the given data prop is only a string or remote is true, this image isn't editable
  if (typeof data === 'string' || data.remote || isSVG) {
    return (
      <AspectRatio aspectRatio={(data as ImageObject).aspectRatio || aspectRatio}>
        <>
          <img
            {...imgSafeProps}
            {...(finalImagePath ? { src: finalImagePath } : {})}
            {...(typeof data === 'object' && data.url2x && data.remote && !isSVG
              ? {
                  srcSet: `${isInLightbox ? data.url2x : data.url} 1x, ${data.url2x} 2x, ${
                    data.url3x
                  } 3x`,
                  width: data.format !== '1_1' ? data.format.split('_')[0] : undefined,
                  height: data.format !== '1_1' ? data.format.split('_')[1] : undefined,
                }
              : {})}
            title={decodingContent(title)}
            alt={decodingContent(alt)}
            ref={imgRef}
            src={isInLightbox ? (data as ImageObject).url2x : (data as ImageObject).url}
          />
          {children}
        </>
      </AspectRatio>
    );
  }

  if (
    typeof data === 'object' &&
    Object.prototype.hasOwnProperty.call(data, 'uid') &&
    data.uid === ''
  ) {
    return null;
  }

  // in case an object has been passed as "data" prop, construct the image tag and
  // wrap the image in an editing handle
  return (
    <ImageEditWrapper
      previewId={isSectionReference ? undefined : data.previewId}
      format={data.format}
      format2x={data.format2x}
      format3x={data.format3x}
      isRemoteMedia={data.remote}
    >
      <AspectRatio
        aspectRatio={shouldHaveImgWrapper ? 'undefined' : data.aspectRatio || aspectRatio}
      >
        {renderWrapper(
          <>
            <img
              {...imgSafeProps}
              {...(finalImagePath ? { src: finalImagePath } : {})}
              {...(data.url2x
                ? {
                    srcSet: `${isInLightbox ? data.url2x : data.url} 1x, ${data.url2x} 2x, ${
                      data.url3x
                    } 3x`,
                    width: data.format !== '1_1' ? data.format.split('_')[0] : undefined,
                    height: data.format !== '1_1' ? data.format.split('_')[1] : undefined,
                  }
                : {})}
              alt={decodingContent(alt)}
              ref={imgRef}
              title={decodingContent(title)}
              src={isInLightbox ? data.url2x : data.url}
            />
            {children}
          </>,
        )}
      </AspectRatio>
    </ImageEditWrapper>
  );
}
