// components
import { InternalLink, type InternalLinkProps } from './InternalLink';
import { MailLink, type MailLinkProps } from './MailLink';
import { PhoneLink, type PhoneLinkProps } from './PhoneLink';
import { ExternalLink, type ExternalLinkProps } from './ExternalLink';
import { CookieLink, type CookieLinkProps } from './CookieLink';

// utils
import { isEmpty } from 'utils/is-empty';

function getLinkComponent(linkType) {
  switch (linkType) {
    case 'internal_link':
    case 'internal_button':
    case 'video_category_link':
      return InternalLink;
    case 'cookie_disclaimer':
      return CookieLink;
    case 'mail_link':
      return MailLink;
    default:
      return ExternalLink;
  }
}

type CmsLinkProps = {
  link?: Link;
  elementType?: string;
} & React.PropsWithChildren<
  Omit<
    InternalLinkProps & ExternalLinkProps & MailLinkProps & PhoneLinkProps & CookieLinkProps,
    'content'
  >
>;

export function CmsLink({
  link,
  children,
  className = '',
  standardFontSize = true,
  hasArrow = false,
  elementType,
  ...props
}: Readonly<CmsLinkProps>) {
  const { target, type } = link ?? {};

  if (isEmpty(link)) {
    return <div className={className}>{children}</div>;
  }

  let LinkComponent = getLinkComponent(type);

  if (!isEmpty(target) && target?.startsWith('/')) {
    LinkComponent = InternalLink;
  }

  if (!isEmpty(target) && target?.startsWith('http')) {
    LinkComponent = ExternalLink;
  }

  if (!isEmpty(target) && target?.startsWith('mailto')) {
    LinkComponent = MailLink;
  }

  if (!isEmpty(target) && target?.startsWith('tel')) {
    LinkComponent = PhoneLink;
  }

  return (
    <LinkComponent
      content={link}
      className={className}
      standardFontSize={standardFontSize}
      hasArrow={hasArrow}
      elementType={elementType}
      {...props}
    >
      {children}
    </LinkComponent>
  );
}
