import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

export function useIsLeadManagementLanding(server: ServerData | null) {
  const path = usePathname();

  return useMemo(() => {
    if (!server) return false;
    const currentUrl = server.baseUrl + path;
    return [
      'https://www.geberit.ch/landingpages-de/traumbad/',
      'https://www.geberit.ch/landingpages-de/traumbad-tools/',
      'https://www.geberit.ch/landingpages-de/traumbad-cashback/',
    ].includes(currentUrl);
  }, [path, server]);
}
