import { useSelector } from 'react-redux';

// utils
import { themeSelector } from '../selectors/globalsSelectors';
import { THEMES, THEME_NAMES, type ThemeNameProps } from 'themes';

export type PossibleThemeName = ThemeNameProps | null | undefined;

export function useThemeName(): ThemeNameProps {
  const themeName = useSelector(themeSelector) || THEME_NAMES.DEFAULT;
  return themeName;
}

export default function useTheme() {
  const themeName = useThemeName();

  if (!themeName) return THEMES.DEFAULT; // includes xf

  return THEMES[themeName] || THEMES.DEFAULT;
}
