import { Button, Link, type LinkProps } from '@geberit/gdds';
import { type MouseEvent, useState } from 'react';
import { useRouter } from 'next/navigation';
import key from 'weak-key';

// types
import type { ContentProps } from './ContentProps';

// components
import { PopUpLink } from './PopUpLink';
import FullscreenIframe from 'components/ContentElementsGdds/fullscreen-iframe/fullscreen-iframe';

// utils
import { externalLink } from './trackingActions';
import { useGdds } from 'utils/hooks/use-gdds';
import { useTracking } from 'utils/hooks/useTracking';
import { useTracking as useTracking2 } from 'utils/tracking/track';
import { decodingContent } from 'utils/decodingContent';
import { addArrowClass } from 'utils/linkArrowClass';
import { classNameBuilder as buildClassName } from 'utils/classNameBuilder';
import { useNord } from 'utils/hooks/use-nord';
import { handlePopup } from 'utils/openInPopup';
import { isEmpty } from 'utils/is-empty';

export type ExternalLinkProps = {
  content: ContentProps;
  className?: string;
  pure?: boolean;
  onClick?(e: MouseEvent<HTMLAnchorElement>): void;
  tracking?(e: MouseEvent<HTMLAnchorElement>): void;
  onMouseDown?(e: MouseEvent<HTMLAnchorElement>): void;
  onMouseUp?(e: MouseEvent<HTMLAnchorElement>): void;
  socialTrackingName?: string;
  socialTrackingIcon?: string;
  trackingCategory?: string;
  allowEmptyLink?: boolean;
  download?: boolean;
  standardFontSize?: boolean;
  hasArrow?: boolean;
  elementType?: string;
  stylingType?: LinkProps['stylingType'];
  alignByContent?: LinkProps['alignByContent'];
  inContext?: boolean;
  icon?: string;
};

export function ExternalLink(props: Readonly<React.PropsWithChildren<ExternalLinkProps>>) {
  const {
    alignByContent,
    content,
    content: {
      showArrow = false,
      target: contentTarget,
      window: linkWindow,
      localMedium,
      type,
      buttonStyle,
    },
    className = '',
    children,
    onClick,
    pure,
    tracking,
    socialTrackingName = '',
    socialTrackingIcon = '',
    trackingCategory = '',
    onMouseUp,
    onMouseDown,
    allowEmptyLink = false,
    download = false,
    stylingType,
    standardFontSize = true,
    hasArrow = false,
    elementType,
    inContext,
    icon,
  } = props;
  const track = useTracking();
  const { trackClick } = useTracking2();
  const isGdds = useGdds();
  const isNord = useNord();
  const [iframeOpen, setIframeOpen] = useState(false);
  const router = useRouter();
  const isButton = type === 'external_button';
  const target = linkWindow;
  const classes = `${className} ${addArrowClass(className, showArrow)}`;
  const isCTA = elementType === 'call_to_action_button';
  const linkText = isEmpty(pure) ? decodingContent(content.text) : content.text;

  if (!contentTarget && !allowEmptyLink) return null;

  if (!isButton && linkWindow === '_popup') {
    return <PopUpLink {...props} />;
  }

  /*
   * renders an internal link which should open in the same window
   */
  let link =
    contentTarget.includes('mailto:') ||
    contentTarget.includes('tel:') ||
    contentTarget.includes('https://') ||
    contentTarget.includes('http://')
      ? contentTarget
      : `https://${contentTarget}`;

  /* eslint-disable-next-line no-script-url */
  if (contentTarget.includes('javascript:')) {
    link = contentTarget;
  }

  const onCloseIframe = () => {
    setIframeOpen(false);
  };

  const onLinkClicked = (e) => {
    if (contentTarget?.toLowerCase()?.endsWith('.pdf')) {
      trackClick({
        click_intent: 'download_pdf',
        click_url: contentTarget,
        file_name: contentTarget?.split('/')?.pop() ?? '',
      });
    }
    if (target === '_self') {
      e.preventDefault();
    }
    if (isButton && target === '_popup') {
      e.preventDefault();
      const windowId = key(content);
      handlePopup(content, windowId);
    }
    if (linkWindow === '_iframe') {
      e.preventDefault();
      setIframeOpen(true);
      return;
    }
    if (isButton && isGdds && target !== '_popup') {
      window.open(contentTarget, target)?.focus();
    }
    if (!tracking) {
      const toLink = e.currentTarget.href || contentTarget;
      const text = e.currentTarget.textContent;
      if (onClick) {
        onClick(e);
      }
      track.trackEvent(
        externalLink(
          toLink,
          text,
          socialTrackingName,
          socialTrackingIcon,
          trackingCategory,
          localMedium,
        ),
      );
      trackClick({
        click_intent: 'outbound_link',
        click_element: 'link',
        click_text: text,
        click_url: toLink,
      });
    } else {
      tracking(e);
    }
    if (target === '_self') {
      router.push(link);
      setTimeout(() => {
        if (link.includes(window.location.hostname)) {
          router.reload();
        }
      }, 250);
    }
  };

  const attributes: React.AllHTMLAttributes<HTMLAnchorElement | HTMLButtonElement> = {
    href: link,
    target,
    className: classes,
    rel: 'noopener noreferrer',
    'aria-label': target,
    onClick: onLinkClicked,
    onMouseDown,
    onMouseUp,
    download,
  };

  if (isEmpty(classes)) {
    delete attributes.className;
  }
  if (target !== '_blank') {
    delete attributes.rel;
  }

  /*
   * If isGdds GDDS-Link usage
   */
  if (isGdds && !isCTA) {
    let defaultStylingType = showArrow ? 'secondary' : 'primary';
    let alignment = alignByContent;
    const LinkComponent = isButton ? Button : Link;
    const content = isButton && isEmpty(children) ? linkText : children;
    const textContent = isEmpty(children) ? linkText : (children as string);
    const linkStylingType = isButton ? buttonStyle : (stylingType ?? defaultStylingType);

    if (!alignment) {
      alignment = showArrow || inContext ? undefined : 'left';
    }

    return (
      <>
        <LinkComponent
          stylingType={linkStylingType}
          text={textContent}
          windowTarget={target}
          target={attributes.href}
          onClick={onLinkClicked}
          standardFontSize={standardFontSize}
          alignByContent={!isButton ? alignment : undefined}
          hasArrow={hasArrow || showArrow}
          inContext={inContext}
          icon={icon}
          className={buildClassName(className, isNord && 'isNordicsStyle')}
          inheritFontSize={!isButton}
        >
          {isButton && (content as any)}
        </LinkComponent>
        {linkWindow === '_iframe' && iframeOpen && (
          <FullscreenIframe src={contentTarget} onClose={onCloseIframe} />
        )}
      </>
    );
  }

  /*
   * If !isGdds <a> usage
   */
  return <a {...attributes}>{isEmpty(children) ? linkText : children}</a>;
}
