import { useCallback, type MouseEvent } from 'react';
import { Link, type LinkProps } from '@geberit/gdds';

// types
import type { ContentProps } from './ContentProps';

// utils
import { mailLink } from './trackingActions';
import { useTracking } from 'utils/hooks/useTracking';
import { classNameBuilder as buildClassName } from 'utils/classNameBuilder';
import { useGdds } from 'utils/hooks/use-gdds';
import { useNord } from 'utils/hooks/use-nord';

function renderLinkContent(children, content) {
  return children ?? content.text;
}

export type MailLinkProps = {
  content: ContentProps;
  className?: string;
  tracking?(e: MouseEvent<HTMLAnchorElement>): void;
  socialTrackingName?: string;
  socialTrackingIcon?: string;
  trackingCategory?: string;
  stylingType?: LinkProps['stylingType'];
  alignByContent?: LinkProps['alignByContent'];
  standardFontSize?: boolean;
  inContext?: boolean;
  icon?: string;
}

export function MailLink({
  content,
  children,
  className = '',
  tracking,
  socialTrackingName,
  socialTrackingIcon,
  trackingCategory,
  stylingType,
  alignByContent,
  standardFontSize = true,
  inContext,
  icon,
}: Readonly<React.PropsWithChildren<MailLinkProps>>) {
  const isGdds = useGdds();
  const isNord = useNord();
  const track = useTracking();

  const handleTracking = useCallback((e) => {
    if (!tracking) {
      let link = e.target.href;
      if (socialTrackingIcon) {
        link = e.target.parentNode.href;
      }

      // remove the @ for ga, should find a better solution for the future
      if (!link) {
        link = content.target;
      }
      if (String(link).includes('@')) {
        link = link.replace('@', '__');
      }

      track.trackEvent(mailLink(link, socialTrackingName, socialTrackingIcon, trackingCategory));
    } else {
      tracking(e);
    }
  }, []);

  if (isGdds) {
    let defaultStylingType: 'secondary' | 'primary' = 'secondary';
    let alignment = alignByContent;

    if (!alignment) {
      alignment = content.showArrow || inContext ? undefined : 'left';
    }

    if (!content.showArrow) {
      defaultStylingType = 'primary';
    }

    return (
      <Link
        stylingType={stylingType ?? defaultStylingType}
        text={renderLinkContent(children, content)}
        target={content.target}
        onClick={handleTracking}
        hasArrow={content.showArrow ?? false}
        alignByContent={alignment}
        className={buildClassName(className, isNord && 'isNordicsStyle')}
        standardFontSize={standardFontSize}
        inContext={inContext}
        icon={icon}
        inheritFontSize
      />
    );
  }

  let ariaLabel = content.text;
  if (!ariaLabel) ariaLabel = content.target;
  if (!ariaLabel) ariaLabel = undefined;

  return (
    <a href={content.target} className={className} onClick={handleTracking} aria-label={ariaLabel}>
      {renderLinkContent(children, content)}
    </a>
  );
}
