// types
import type { JSX } from 'react';

// utils
import { TPP_TYPE_IMAGE } from './tppConstants';
import { useIsPreview } from '../../utils/hooks/useIsPreview';

type ImageEditWrapperProps = {
  previewId?: string;
  format?: string;
  format2x?: string;
  format3x?: string;
  additionalClass?: string;
  isRemoteMedia?: boolean;
  tppIsPage?: boolean;
};

export function ImageEditWrapper({
  previewId,
  children,
  format,
  format2x,
  format3x,
  isRemoteMedia,
  additionalClass = '',
  tppIsPage,
}: Readonly<React.PropsWithChildren<ImageEditWrapperProps>>) {
  const isPreview = useIsPreview();
  if (!isPreview || !previewId || isRemoteMedia) {
    if (!children) return null;
    return children as JSX.Element;
  }

  return (
    <div
      className={`c-tpp-area ${additionalClass}`}
      data-preview-id={previewId}
      data-image-format={format}
      data-image-format2x={format2x}
      data-image-format3x={format3x}
      data-edit-type={TPP_TYPE_IMAGE}
      data-tpp-is-page={tppIsPage}
    >
      {children}
    </div>
  );
}
